import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {}

  navigate(event) {
    if (window.Turbolinks) {
      window.Turbolinks.visit(event.currentTarget.dataset.href);
    } else if (window.Turbo)
      window.Turbo.visit(event.currentTarget.dataset.href);
    else {
      window.location.href = event.currentTarget.dataset.href;
    }
  }
}
