import { Controller } from "@hotwired/stimulus";
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = ["nomination", "priority"];

  connect() {
    Sortable.create(this.element, {
      animation: 150,
      draggable: "[data-draggable]",
      onEnd: _event => {
        const nominationIds = this.nominationTargets.map(
          node => node.dataset.nominationId
        );

        const url = this.data.get("url");
        console.log(nominationIds);
        $.ajax(url, {
          data: { nomination_ids: nominationIds },
          method: "POST",
          dataType: "text/html"
        });

        this.updatePriorities();
      }
    });
  }

  updatePriorities() {
    this.priorityTargets.forEach(
      (node, i) => (node.innerHTML = (i + 1).toString())
    );
  }
}
