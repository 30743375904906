import { Controller } from "@hotwired/stimulus";

const WARNING_WHEN_REMAINING_SECS = 300; // 5 minutes
const DEFAULT_POLL_SECS = 3;

const MAX_POLL_COUNT = (60 * 60 * 10) / DEFAULT_POLL_SECS; // about 10 hours
const TS_KEY = "session_last_request_ts";
const UID_KEY = "session_user_id";

/*
 * Loop state for the controller. Extracted into a object to make it easier to manage
 */
type State = {
  userId: string | undefined;
  remaining: number;
  pollCount: number;
  expired: boolean;
  invalid: boolean;
  xhr?: JQuery.jqXHR;
};

const getTimestamp = () => {
  const now = new Date();
  return now.getTime() / 1000;
};

/*
 * localstorage for cross-tab communication
 */
const shared = {
  saveValue(key: string, value: string | number) {
    try {
      window.localStorage.setItem(key, String(value));
    } catch {
      undefined;
    }
  },
  getValue(key: string) {
    try {
      return window.localStorage.getItem(key) || undefined;
    } catch {
      return undefined;
    }
  }
};

/*
 * Handle session expiration
 *
 * - tracks the current user id and last authenticated request timestamp in local storage. Tracking occurs onpage load and ajax requests
 * - poll local storage and adjust display to:
 *   A. Warn the user if the current session is about to expire
 *   B. Show an alert if the session expires
 *   C. Show an alert if the current user changes
 *   D. Refresh the page is the user logs in again in another tabafter the page has expired
 *
 * - for debugging, see window.stimulusApp.controllers
 * - inactive session modal is not a great name but stimulus controllers are hard to rename
 */
export default class extends Controller {
  static targets = ["timeRemaining", "modal", "alert", "alertMessage"];
  timeRemainingTarget!: HTMLInputElement;
  modalTarget!: HTMLInputElement;
  alertTarget!: HTMLInputElement;
  alertMessageTarget!: HTMLInputElement;

  initialUserIdValue!: string;
  sessionLifetimeSecsValue!: number;

  mainLoopInterval!: NodeJS.Timeout;

  state!: State;

  connect() {
    // Stimulus v1 doesn't support data attrs
    this.initialUserIdValue = this.data.get("initial-user-id-value") as string;
    this.sessionLifetimeSecsValue = parseInt(this.data.get(
      "session-lifetime-secs-value"
    ) as string);

    // persist timestamp and user on page load to shared store
    shared.saveValue(UID_KEY, this.initialUserIdValue);
    if (this.initialUserIdValue) {
      shared.saveValue(TS_KEY, getTimestamp());
    } else {
      // no user id, so exit after updating the local state
      return
    }

    this.state = {
      userId: this.initialUserIdValue,
      remaining: Number.MAX_VALUE,
      expired: false,
      invalid: false,
      xhr: undefined,
      pollCount: 0
    };

    // when xhr requests are seen, update the shared store
    $(document).on("ajaxComplete", this.handleAjaxComplete);

    // start polling
    this.mainLoop();

    document.addEventListener("shown.bs.modal", this.ensureOneBackdrop);
    // IE / Edge Hack - add noop storage listener so cross-tab values are visible
    window.onstorage = function() {};
  }

  disconnect() {
    this.state.xhr?.abort();
    document.removeEventListener("shown.bs.modal", this.ensureOneBackdrop);
    $(document).off("ajaxComplete", this.handleAjaxComplete);
    window.onstorage = null;
    if (this.mainLoopInterval) {
      clearTimeout(this.mainLoopInterval);
    }
  }

  mainLoop() {
    const { state } = this;
    state.pollCount += 1;

    // stop polling if we exceed the safety count
    if (state.pollCount > MAX_POLL_COUNT) {
      this.renderAlert("There was an error in your session");
      // @ts-ignore
      Sentry?.captureMessage(new Error('max poll count exceeded'));
      return;
    }
    if (state.invalid || state.expired) {
      // stop polling if session has expired
      return
    }

    // check localstorage for a new user id and session timeout
    state.userId = shared.getValue(UID_KEY);
    const ts = parseInt(shared.getValue(TS_KEY) as string);
    if (ts) {
      const expires = ts + this.sessionLifetimeSecsValue;
      const delta = expires - getTimestamp();
      const remaining = delta > 0 ? delta : 0;
      state.remaining = remaining;
      // poll every second when showing a warning to update timer
      const timeout =
        remaining > 0 && remaining <= WARNING_WHEN_REMAINING_SECS
          ? 1000
          : DEFAULT_POLL_SECS * 1000;
      this.mainLoopInterval = setTimeout(() => this.mainLoop(), timeout);
    }

    if (state.userId !== this.initialUserIdValue) {
      // another tab has logged out or changed user
      state.invalid = true;
    } else if (state.remaining === 0) {
      state.expired = true;
    }

    if (state.expired) {
      this.renderAlert("Your session has expired.");
    } else if (state.invalid) {
      this.renderAlert(
        "Your session is invalid. You may have signed out in another window."
      );
    } else if (state.remaining < WARNING_WHEN_REMAINING_SECS) {
      this.renderWarning(state);
    } else {
      this.hideWarning();
    }
  }

  renderWarning({ remaining }: State) {
    const minRemaining = Math.floor(remaining / 60);
    const secRemaining = Math.floor(remaining % 60);
    this.timeRemainingTarget.innerHTML = `${minRemaining} minutes, ${secRemaining} seconds`;
    $(this.modalTarget).modal("show");
  }

  hideWarning() {
    $(this.modalTarget).modal("hide");
  }

  renderAlert(message: string) {
    this.clearBody();
    this.hideWarning();
    const $e = $(this.alertMessageTarget);
    if ($e.text() !== message) $e.text(message);
    $(this.alertTarget).removeClass("d-none");
  }

  /*
   * remove extra backdrops if warning modal is shown over another modal
   */
  ensureOneBackdrop() {
    document.querySelectorAll(".modal-backdrop").forEach((node, i) => {
      if (i > 0) node.remove();
    });
  }

  /*
   * remove all elements from the body except this controller's element
   */
  clearBody() {
    let node: ChildNode;
    if (!document.body) return;
    for (let i = 0; i < document.body.childNodes.length; i++) {
      node = document.body.childNodes[i];
      if (node.nodeType == 1 && !node.isEqualNode(this.element)) {
        document.body.removeChild(node);
      }
    }
  }

  /*
   * when an XHR request is received, update the shared store
   */
  handleAjaxComplete(_evt, xhr) {
    const userId = xhr.getResponseHeader("X-user-id");
    shared.saveValue(UID_KEY, userId);
    if (userId) {
      // user id in header means we have a valid session
      shared.saveValue(TS_KEY, getTimestamp());
    }
  }

  /*
   * user clicks "log in" on the alert page
   */
  handleLogin(event) {
    event.preventDefault();
    window.location.reload();
  }

  /*
   * user clicks "continue using" on warning screen, refresh the session without a page load
   */
  handleRenewSession(event) {
    event.preventDefault();
    // already in flight
    if (this.state.xhr) return;

    const success = () => {
      this.state.xhr = undefined;
      this.hideWarning();
    };
    const error = () => {
      // network error occured, do a full page reload
      window.location.reload();
    };
    this.state.xhr = $.ajax(event.currentTarget.href, {
      method: "POST",
      success,
      error
    });
  }
}
