import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "formScreen", "confirmationScreen",
    "companyInput", "companyConfirmation",
    "titleInput", "titleConfirmation",
    "typeInput", "typeConfirmation",
    "fileInput", "fileConfirmation"
  ]

  connect() {
    window.addEvidenceController = this
  }

  showConfirmation() {
    const selectedCompanyOption = this.companyInputTarget.querySelectorAll("option:checked")[0]
    if (selectedCompanyOption) {
      this.companyConfirmationTarget.innerHTML = selectedCompanyOption.innerHTML
    }

    this.titleConfirmationTarget.innerHTML = this.titleInputTarget.value

    const selectedTypeOption = this.typeInputTarget.querySelectorAll("option:checked")[0]
    if (selectedTypeOption) {
      this.typeConfirmationTarget.innerHTML = selectedTypeOption.innerHTML
    }

    const file = this.fileInputTarget.files[0]
    if (file) {
      this.fileConfirmationTarget.innerHTML = file.name
    } else {
      this.fileConfirmationTarget.innerHTML = ""
    }

    this.formScreenTarget.style.display = "none"
    this.confirmationScreenTarget.style.removeProperty("display")

    $(".js-confirmation-submit").removeClass("d-none")
    $(".js-confirmation-submit").addClass("d-flex")

    $(".js-form-submit").addClass("d-none")
    $(".js-form-submit").removeClass("d-flex")
  }

  showForm() {
    this.formScreenTarget.style.removeProperty("display")
    this.confirmationScreenTarget.style.display = "none"

    $(".js-form-submit").removeClass("d-none")
    $(".js-form-submit").addClass("d-flex")

    $(".js-confirmation-submit").addClass("d-none")
    $(".js-confirmation-submit").removeClass("d-flex")
  }

}
