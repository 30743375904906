/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "regenerator-runtime/runtime";
import { Turbo, cable as cableIgnored } from "@hotwired/turbo-rails";
window.Turbo = Turbo;

import Rails from "rails-ujs";
window.Rails = Rails;
Rails.start();

////////////////////////////
// Stimulus Framework
////////////////////////////

// stimulus init
import { Application } from "@hotwired/stimulus";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";

const application = Application.start();
const appContext = require.context("controllers", true, /.(js|ts)$/);
application.load(definitionsFromContext(appContext));
window.stimulusApp = application;

// StimulusReflex;
import StimulusReflex from "stimulus_reflex";
import consumer from "../channels/consumer";
import controller from "../dda/controllers/stimulus_reflex_controller"; //TODO make a dda app controller
application.consumer = consumer
StimulusReflex.initialize(application, {
  controller,
  debug: false,
  isolate: true
});
if (process.env.RAILS_ENV != "production") StimulusReflex.debug = true;
window.StimulusReflex = StimulusReflex;

// CableReady
import CableReady from "cable_ready";
CableReady.initialize({ consumer });

$(function() {
  // always pass csrf tokens on ajax calls
  $.ajaxSetup({
    headers: { "X-CSRF-Token": Rails.csrfToken() }
  });
});

import React from "react";
window.React = React;
import ReactDOM from "react-dom";
window.ReactDOM = ReactDOM;

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
window.ReactRailsUJS = ReactRailsUJS;

// trix + actiontext
// window.Trix = require("trix");
// require("@rails/actiontext");
