import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.intervalId = setInterval(() => {
      const csrfToken = document.querySelector("[name='csrf-token']").content;
      fetch(this.element.dataset.url, {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken
        }
      });
    }, 5000);
  }

  disconnect() {
    clearInterval(this.intervalId);
  }
}
