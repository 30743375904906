import { Controller } from "@hotwired/stimulus";
import StimulusReflex from "stimulus_reflex";

/* This is your ApplicationController.
 * All StimulusReflex controllers should inherit from this class.
 *
 * Example:
 *
 *   import ApplicationController from './application_controller'
 *
 *   export default class extends ApplicationController { ... }
 *
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  /* Application-wide lifecycle methods
   *
   * Use these methods to handle lifecycle concerns for the entire application.
   * Using the lifecycle is optional, so feel free to delete these stubs if you don't need them.
   *
   * Arguments:
   *
   *   element - the element that triggered the reflex
   *             may be different than the Stimulus controller's this.element
   *
   *   reflex - the name of the reflex e.g. "Example#demo"
   *
   *   error/noop - the error message (for reflexError), otherwise null
   *
   *   reflexId - a UUID4 or developer-provided unique identifier for each Reflex
   */

  beforeReflex(_element, _reflex, _noop, reflexId) {
    const pageId = document.querySelector("body").dataset.pageId;
    const pagePreviouslyVisited =
      document.querySelectorAll("body.j-page_previously_visited").length > 0;
    const { params } = this.element.reflexData[reflexId];
    this.element.reflexData[reflexId].params = {
      ...params,
      page_id: pageId,
      page_previously_visited: pagePreviouslyVisited
    };
  }

  // get a reference to the DDA autosave queue and disable / enable internal links while queueing
  useAutoSaveQueue() {
    this.autosaveQueue = window.ddaResponseController.autosaveQueue;

    this.autosaveQueue.beforePush(() => {
      this.element
        .querySelectorAll(".js-create, .js-delete")
        .forEach(e => (e.disabled = true));
    });

    this.autosaveQueue.onDrain(() => {
      this.element
        .querySelectorAll(".js-create, .js-delete")
        .forEach(e => (e.disabled = false));
    });
  }

  // subclasses should set this.reflexClass at connect
  runReflex(reflexAction, reflexScope = "") {
    const { currentTarget } = event;
    const { reflexClass, autosaveQueue } = this;
    if (currentTarget.disabled) return;

    let taskId = `${reflexClass}#${reflexAction}`;
    if (reflexScope) {
      taskId += `:${reflexScope}`;
    }

    autosaveQueue.push({
      taskId,
      perform: async () => {
        await this.stimulate(`${reflexClass}#${reflexAction}`, currentTarget);
      }
    });
  }
}
