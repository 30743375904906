import { Controller } from "@hotwired/stimulus";
import SlimSelect from "slim-select";

export default class extends Controller {
  connect() {
    this.slimSelect = new SlimSelect({
      select: this.element,
      showContent: "down"
    });
  }

  disconnect() {
    this.slimSelect.destroy();
  }
}
