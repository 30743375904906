import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newEntityFields", "existingEntityPicker", "prompt"]

  connect() {
    // console.log("Hello!")
    if (this.data.get("activateOnLoad")) {
      this.activateNewEntityFields()
    }
  }

  activateNewEntityFields() {
    $(this.newEntityFieldsTarget).removeClass("d-none")
    $(this.promptTarget).addClass("d-none")
    $(this.newEntityFieldsTarget).find('input, textarea, select').removeAttr('disabled')
    this.existingEntityPickerTarget.disabled = true
  }
}
