import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    const url = this.data.get("url");
    let count = 0;
    let checkInt;
    let inFlight = false
    const pollStatus = () => {
      count += 1;
      // stop polling if count exceeded or request never returned
      if (count < 50 && !inFlight) {
        inFlight = true
        fetch(url)
          .then(response => response.json())
          .then(({ ddr_generation_status }) => {
            inFlight = false
            if (
              ["processing_complete", "error"].includes(ddr_generation_status)
            ) {
              window.location.reload();
            }
          })
          .catch(ex => {
            clearInterval(checkInt);
            inFlight = false
            console.log("checking DdrGenerationStatus failed", ex);
            Sentry.captureException(ex);
          });
      } else {
        console.log("polling exceeded timeout");
        clearInterval(checkInt);
      }
    };
    checkInt = setInterval(pollStatus, 5000);
  }
}
