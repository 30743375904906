import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", setConfirmation);
    this.element.addEventListener("submit", unsetConfirmation);
  }

  disconnect() {
    this.element.removeEventListener("change", setConfirmation);
    this.element.removeEventListener("submit", unsetConfirmation);
    unsetConfirmation();
  }
}

const msg =
  "Are you sure you want to leave this page? Your changes will not be saved.";

function setConfirmation(_event) {
  window.addEventListener("beforeunload", confirmUnload);
  document.addEventListener("turbo:before-visit", turboConfirmUnload);
}

function unsetConfirmation(_event) {
  window.removeEventListener("beforeunload", confirmUnload);
  document.removeEventListener("turbo:before-visit", turboConfirmUnload);
}

function confirmUnload(event) {
  event.preventDefault();
  event.returnValue = true;
  return msg;
}

function turboConfirmUnload(event) {
  if (window.confirm(msg)) {
    unsetConfirmation();
  } else {
    event.preventDefault();
  }
}
