import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "workerCountInput",
    "unexpectedWorkerCountWarning",
    "upstreamFlows",
    "originEntitySelect",
    "destinationEntitySelect",
    "countrySelect",
    "setWorkerCountButton"
  ]

  connect() {
    window.workerFlowFormController = this
  }

  setWorkerCountValueFromExpected() {
    this.workerCountInputTarget.value = this.data.get("expectedWorkerCount")
    $(this.unexpectedWorkerCountWarningTarget).addClass("d-none")
  }

  refreshWarningVisibility() {
    if (this.data.get("hasUpstreamFlows") != "true" || this.workerCountInputTarget.value == this.data.get("expectedWorkerCount") ) {
      $(this.unexpectedWorkerCountWarningTarget).addClass("d-none")
    } else {
      $(this.unexpectedWorkerCountWarningTarget).removeClass("d-none")
    }
  }

  refreshSetWorkerCountButtonVisibility() {
    if (this.data.get("hasUpstreamFlows") == "true") {
      this.setWorkerCountButtonTarget.style.visibility = "visible"
    } else {
      this.setWorkerCountButtonTarget.style.visibility = "hidden"
    }
  }

  updateUpstreamFlows() {
    $.ajax({
      url: this.data.get("upstreamFlowsUrl"),
      dataType: "json",
      method: "GET",
      data: {
        origin_entity_id: this.originEntitySelectTarget.value,
        destination_entity_id: this.destinationEntitySelectTarget.value,
        country_id: this.countrySelectTarget.value
      },
      error: () => {alert("Sorry, an error occurred.  Please reload the page.")},
      success: (responseData) => {
        this.data.set("expectedWorkerCount", responseData.expectedWorkerCount)
        this.data.set("hasUpstreamFlows", responseData.hasUpstreamFlows)
        this.upstreamFlowsTarget.innerHTML = responseData.upstreamFlowsHtml
        this.refreshWarningVisibility()
        this.refreshSetWorkerCountButtonVisibility()
      }
    })
  }
}
